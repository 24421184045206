import { Card, CardFront, CardBack } from '@indigenux/design/components';
import React from 'react';
const B2BSoftware = (...rest) => {

	return (
  	<Card canFlip={true}>
		<CardFront>
			<strong className="font-weight-extra-bold text-color-dark line-height-1 text-13 mb-3 d-inline-block">
				<i className="icon-featured fas fa-chart-pie text-color-light"></i>
			</strong>
			<h4 className="font-weight-bold text-color-primary text-4">B2B Software</h4>
			<p>Power your B2B success with our expertise in software solutions. In the highly competitive world of B2B, having the right software solutions can make all the difference. We deliver tailored B2B software expertise that empowers businesses like yours to thrive.</p>
		</CardFront>
		<CardBack>
			<h4 className="font-weight-bold text-color-light">&nbsp;</h4>
			<p className="font-weight-light text-color-light opacity-5">With a deep understanding of the unique challenges and requirements of the B2B landscape, our consultants will work closely with you to identify your specific needs and develop custom software solutions that drive efficiency, enhance collaboration, and fuel growth. Whether it's optimizing supply chain management, implementing CRM systems, or integrating e-commerce platforms, our experts bring a wealth of knowledge to guide you through every step of your B2B software journey. Trust us to elevate your B2B operations and unlock new possibilities for success.</p>
			<a href="contact-us" className="btn btn-light btn-modern text-color-dark font-weight-bold">LEARN MORE</a>
		</CardBack>
	</Card>
    );
};

export default B2BSoftware;