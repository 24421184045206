import React from 'react';
import { Divider, IndiText } from '@indigenux/design/components';
import { Main } from '../../components';
import { BrandIdentity, SystemArchitecture, CustomizedSolutions, SupportServices, WebDesignDevelopment, SearchEngineOptimization, B2BSoftware } from './components/cards';

const ServicesView = () => {

	return (<Main>
		<section className="page-header page-header-classic">
			<div className="container">
				<div className="row">
					<div className="col">
						<ul className="breadcrumb">
							<li><a href="/">Home</a></li>
							<li className="active text-color-light">Services</li>
						</ul>
					</div>
				</div>
				<div className="row">
					<div className="col p-static">
						<h1>Services @<IndiText uxCSSClass="text-color-primary"></IndiText></h1>
					</div>
				</div>
			</div>
		</section>

		<div className="container py-2">
			<div className="row mb-5 pb-3">
				<div className="col-lg-12 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible" style={{ "animationDelay": "200ms" }}>
					<SystemArchitecture></SystemArchitecture>
				</div>
			</div>

			<Divider></Divider>
			<div className="row mb-5 pb-3">
				<div className="col-md-6 col-lg-6 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible" style={{ "animationDelay": "200ms" }}>
					<BrandIdentity></BrandIdentity>
				</div>
				<div className="col-md-6 col-lg-6 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible" style={{ "animationDelay": "200ms" }}>
					<CustomizedSolutions></CustomizedSolutions>
				</div>
			</div>
			<div className="row mb-5 pb-3">
				<div className="col-md-6 col-lg-4 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible" style={{ "animationDelay": "200ms" }}>
					<SupportServices></SupportServices>
				</div>
				<div className="col-md-6 col-lg-4 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible" style={{ "animationDelay": "200ms" }}>
					<WebDesignDevelopment></WebDesignDevelopment>
				</div>

				<div className="col-md-6 col-lg-4 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible" style={{ "animationDelay": "200ms" }}>
					<SearchEngineOptimization></SearchEngineOptimization>
				</div>
			</div>

			<Divider></Divider>
			<div className="row mb-5 pb-3">
				<div className="col-md-6 col-lg-12 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible" style={{ "animationDelay": "200ms" }}>
					<B2BSoftware></B2BSoftware>
				</div>
			</div>
		</div>
	</Main>
	);
};

export default ServicesView;