import React from 'react';
import { Divider, IndiText, PageHeader, Heading } from '@indigenux/design/components';
import PageLogo from 'img/slides/josephina-kolpachnikof-xBNXK_bWqLY-unsplash.jpg';
import { Main } from '../../components';

const CreativeView = () => {

	return (<Main>
		<PageHeader Category="Creative">
			Creative @<IndiText uxCSSClass="text-color-quaternary"></IndiText>
		</PageHeader>
		<div className="container py-2">

			<div className="row">
				<div className="col">
					<img className="float-start img-fluid me-2" width="300" src={PageLogo} alt="Device" />
					<Heading level="h2">
						Ignite your digital presence with <IndiText uxCSSClass="text-color-quaternary"></IndiText>: Your trusted creative consultancy partner
					</Heading>

					<p className="lead">Elevate your brand with <IndiText uxCSSClass="text-color-quaternary"></IndiText>.</p>

					<p>Are you ready to captivate your audience, inspire action, and make a lasting impression in the digital world? Look no further than <IndiText uxCSSClass="text-color-quaternary"></IndiText>, the unified technology partner that combines creativity, innovation, and cutting-edge technology to bring your brand to life.</p>

					<Divider uxCSSClass='divider-quaternary'></Divider>
					<Heading level="h2">
						Why choose <IndiText uxCSSClass="text-color-quaternary"></IndiText>?
					</Heading>

					<ul className="list list-icons list-icons-style-2">
						<li><i className="fas fa-palette text-color-quaternary"></i> Unleashing Creative Brilliance: At <IndiText uxCSSClass="text-color-quaternary"></IndiText>, we believe in the power of creativity to transform businesses. Our team of passionate designers, developers, and strategists are experts in their craft, with a knack for turning ideas into captivating digital experiences. We think outside the box to deliver unique and visually stunning designs that reflect your brand's essence and engage your target audience.</li>
						<li><i className="fas fa-users text-color-quaternary"></i> Seamless User Experience: We understand that a great design is not just about aesthetics; it's about creating a seamless user experience. Our user-centric approach ensures that every interaction with your digital assets is intuitive, immersive, and delightful. From intuitive website navigation to immersive mobile app interfaces, we craft designs that not only look amazing but also drive conversions and keep users coming back for more.</li>
						<li><i className="fas fa-compass-drafting text-color-quaternary"></i> Cutting-Edge Technology: In a rapidly evolving digital landscape, staying ahead of the curve is crucial. At <IndiText uxCSSClass="text-color-quaternary"></IndiText>, we leverage the latest technologies and tools to create innovative and future-proof solutions for our clients. Whether it's responsive web design, interactive multimedia content, or immersive virtual reality experiences, we harness the power of technology to make your brand stand out from the crowd.</li>
						<li><i className="fas fa-handshake text-color-quaternary"></i> Collaborative Partnership: We believe that great design is born out of collaboration. We value your input and work closely with you throughout the design process to ensure that your vision is brought to life. Our team takes the time to understand your brand identity, target audience, and business objectives, allowing us to create designs that align perfectly with your goals. Your success is our success, and we are dedicated to going the extra mile to exceed your expectations.</li>
						<li><i className="fas fa-layer-group text-color-quaternary"></i> Results-Driven Approach: At <IndiText uxCSSClass="text-color-quaternary"></IndiText>, we are not just about creating beautiful designs; we are driven by results. We combine our artistic prowess with data-driven insights to design solutions that generate measurable outcomes for your business. Whether it's increased website traffic, higher conversion rates, or enhanced brand recognition, we focus on delivering tangible results that impact your bottom line.</li>
					</ul>

					<Divider uxCSSClass='divider-quaternary'></Divider>
					<Heading level="h2">
						Experience the <IndiText uxCSSClass="text-color-quaternary"></IndiText> difference today
					</Heading >

					<p>Don't settle for ordinary when you can have extraordinary. Choose <IndiText uxCSSClass="text-color-quaternary"></IndiText> as your digital design agency and unlock the full potential of your brand in the digital realm. <a href="contact-us">Contact us</a> today to schedule a consultation and discover how our innovative designs can propel your business to new heights.</p>

					<p className="lead">Discover how our tailored solutions can revolutionize your business.</p>
					<p>Ignite your digital presence. Choose <IndiText uxCSSClass="text-color-quaternary"></IndiText>.</p>
				</div>
			</div>
		</div>
	</Main>
	);
};

export default CreativeView;