import { Card, CardFront, CardBack } from '@indigenux/design/components';
import React from 'react';
const WebDesignDevelopment = (...rest) => {

	return (
  	<Card canFlip={true}>
		<CardFront>
			<strong className="font-weight-extra-bold text-color-dark line-height-1 text-13 mb-3 d-inline-block">
				<i className="icon-featured fas fa-sitemap text-color-light"></i>
			</strong>
			<h4 className="font-weight-bold text-color-primary text-4">Website Design & Development</h4>
			<p>Transform your online presence with our expert Website Design and Development consultants. Your website is the virtual storefront of your business, and making a captivating first impression is crucial. We have the knowledge and experience to help you create a remarkable online presence.</p>
		</CardFront>
		<CardBack>
			<h4 className="font-weight-bold text-color-light"></h4>
			<p className="font-weight-light text-color-light opacity-5">Our team of skilled designers and developers will collaborate closely with you to understand your brand, target audience, and business goals. With a meticulous approach to user experience and cutting-edge design techniques, we craft visually stunning websites that not only captivate visitors but also drive conversions. From responsive designs and intuitive navigation to seamless functionality and optimized performance, our experts ensure your website stands out from the competition. Trust us to bring your digital vision to life and establish a powerful online presence that drives your business forward.</p>
			<a href="contact-us" className="btn btn-light btn-modern text-color-dark font-weight-bold">LEARN MORE</a>
		</CardBack>
	</Card>
    );
};

export default WebDesignDevelopment;