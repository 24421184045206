import { React } from 'react';
import { Carousel, IndiText, Heading, Link, Highlight } from '@indigenux/design/components';
import { Highlights } from './components';
import { Main } from '../../components';


import van from "img/slides/wim-van-t-einde-LTEZDahGu9M-unsplash.jpg";
import officeImage from "img/slides/office-581127_1920.jpg";

const HomeView = () => {

	return (<Main>
		<Highlights></Highlights>
		<div className="home-intro mb-0" id="home-intro">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-8">
						<p>
							Our unified experience approach to technology consulting means delivering successful outcomes to you without separating you from your wallet.
							<Highlight>Innovation</Highlight>
							<span>Take a look at what we do, how we do it, and why.</span>
						</p>
					</div>
					<div className="col-lg-4">
						<div className="get-started text-start text-lg-end">
							<Link href="contact-us" className="btn btn-primary btn-lg text-3 font-weight-semibold px-4 py-3">LET'S TALK</Link>
							<div className="learn-more">or <Link href="success">learn more.</Link></div>
						</div>
					</div>
				</div>

			</div>
		</div>

		<section className="section section-parallax section-height-3 border-0 m-0"
			data-plugin-parallax
			data-plugin-options="{'speed': 7, 'parallaxHeight': '150%', 'offset': 40}"
			style={{ backgroundImage: `url(${van})`, backgroundSize: "cover", backgroundPosition: "center" }}>
			<div className="container pb-5 mb-5">
				<div className="row text-center pb-5 mb-5">
					<div className="col-md-10 mx-md-auto pb-5 mb-5">
						<Heading level="h2" cssName="word-rotator slide font-weight-normal text-8 mb-3 text-color-light" data-appear-animation="fadeInUpShorter">
							<IndiText uxCSSClass="text-color-primary"></IndiText> is founded upon on a
							<br />
							<span className="word-rotator-words text-10">
								<b className="is-visible text-color-secondary">natural</b>
								<b className="text-primary">successful</b>
								<b className="text-color-quaternary">proven</b>
								<b className="text-color-tertiary">unified</b>
							</span><br />
							<span> approach to technology consulting.</span>
						</Heading>
					</div>
				</div>
			</div>
		</section>

		<section className="section bg-color-grey-scale-3 section-height-3 border-0 m-0">
			<div className="container">
				<div className="row">
					<div className="col">
						<Heading level="h2" cssName="font-weight-normal text-center text-6 pb-3">Our <strong className="font-weight-extra-bold">Services</strong></Heading>
					</div>
				</div>
				<div className="row mb-lg-4 " data-aos="fade-left" data-aos-delay="300">
					<div className="col-lg-4">
						<div className="feature-box feature-box-style-2">
							<div className="feature-box-icon">
								<i className="icons icon-support text-color-primary"></i>
							</div>
							<div className="feature-box-info">
								<Heading level="h4" cssName="font-weight-bold mb-2">Customized Solutions</Heading>
								<p>We take the time to understand <Highlight>your</Highlight> unique business requirements, goals, and constraints.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4" data-aos="fadeInUpShorter">
						<div className="feature-box feature-box-style-2">
							<div className="feature-box-icon">
								<i className="icons icon-layers text-color-primary"></i>
							</div>
							<div className="feature-box-info">
								<Heading level="h4" cssName="font-weight-bold mb-2">Website Design & Development</Heading>
								<p>Our agile development approach ensures quick turnaround times, continuous feedback loops, and flexibility in adapting to evolving project requirements.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
						<div className="feature-box feature-box-style-2">
							<div className="feature-box-icon">
								<i className="icons icon-menu text-color-primary"></i>
							</div>
							<div className="feature-box-info">
								<Heading level="h4" cssName="font-weight-bold mb-2">Brand & Identity</Heading>
								<p>We think outside the box to deliver unique and visually stunning designs that reflect <Highlight>your</Highlight> brand's essence and engage your target audience.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
						<div className="feature-box feature-box-style-2">
							<div className="feature-box-icon">
								<i className="icons icon-doc text-color-primary"></i>
							</div>
							<div className="feature-box-info">
								<Heading level="h4" cssName="font-weight-bold mb-2">Search Engine Optimization</Heading>
								<p>We combine our artistic prowess with data-driven insights to design solutions that generate measurable outcomes for <Highlight>your</Highlight> business. </p>
							</div>
						</div>
					</div>
					<div className="col-lg-4" data-appear-animation="fadeInUpShorter">
						<div className="feature-box feature-box-style-2">
							<div className="feature-box-icon">
								<i className="icons icon-user text-color-primary"></i>
							</div>
							<div className="feature-box-info">
								<Heading level="h4" cssName="font-weight-bold mb-2">B2B Software</Heading>
								<p>Our rigorous quality assurance processes ensure that <Highlight>your</Highlight> software is thoroughly tested, secure, and meets the highest industry standards.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
						<div className="feature-box feature-box-style-2">
							<div className="feature-box-icon">
								<i className="icons icon-screen-desktop text-color-primary"></i>
							</div>
							<div className="feature-box-info">
								<Heading level="h4" cssName="font-weight-bold mb-2">Support Services</Heading>
								<p>Our dedicated support team is always at hand to address any concerns, provide timely assistance, and help you leverage your technology investment to its fullest potential.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section className="section section-height-3 section-background section-text-light section-center overlay overlay-show overlay-op-5 overlay-color-primary m-0" data-appear-animation="fadeIn" style={{ backgroundImage: `url(${officeImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-10" data-aos="fadeInUpShorter" data-aos-delay="200">
						<Carousel id="carousel-2" autoPlay={true} infiniteLoop={true} interval={5000}>
							<div>
								<div className="col">
									<div className="testimonial testimonial-style-2 testimonial-with-quotes mb-0">
										<div className="testimonial-author">
											<span><i className="fa-solid fa-user text-13 text-color-primary-alt"></i></span>
										</div>
										<blockquote>
											<p className="text-6">On time. Under budget. Wow. Just, wow.</p>
										</blockquote>
										<div className="testimonial-author">
											<p><strong className="text-2 opacity-10">- Don Peteroy, University of Cincinnati</strong></p>
										</div>
									</div>
								</div>
							</div>
							<div>
								<div className="col">
									<div className="testimonial testimonial-style-2 testimonial-with-quotes mb-0">
										<div className="testimonial-author">
											<span><i className="fa-solid fa-user text-13 text-color-dark"></i></span>
										</div>
										<blockquote>
											<p className="text-6">Indigenux has always delivered highly performant solutions in a timeframe that I can live with. When I'm in a pinch, I know who I trust to help me get across the finish line.</p>
										</blockquote>
										<div className="testimonial-author">
											<p><strong className="text-2 opacity-10">- Mark Ramey, Independent Contractor</strong></p>
										</div>
									</div>
								</div>
							</div>
						</Carousel>
					</div>
				</div>
			</div>
		</section>

		<section className="call-to-action call-to-action-strong-grey content-align-center call-to-action-in-footer">
			<div className="container">
				<div className="row">
					<div className="col-md-9 col-lg-9">
						<div className="call-to-action-content">
							<Heading level="h2" cssName="font-weight-normal text-6 mb-0"><IndiText uxCSSClass="text-color-primary"></IndiText> is <strong>everything</strong> you need to drive tangible results.</Heading>
							<p className="mb-0">Contact us today to find out how we can help you achieve success.</p>
						</div>
					</div>
					<div className="col-md-3 col-lg-3">
						<div className="call-to-action-btn">
							<Link href="success" className="btn btn-modern btn-primary">NEXT STEPS</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	</Main>
	);
};

export default HomeView;