import { Card, CardFront, CardBack } from '@indigenux/design/components';
import React from 'react';
const SearchEngineOptimization = (...rest) => {

	return (
  	<Card canFlip={true}>
		<CardFront>
			<strong className="font-weight-extra-bold text-color-dark line-height-1 text-13 mb-3 d-inline-block">
				<i className="icon-featured fas fa-magnifying-glass-arrow-right text-color-light"></i>
			</strong>
			<h4 className="font-weight-bold text-color-primary text-4">Search Engine Optimization</h4>
			<p>Ignite your online visibility with oure expert Search Engine Optimization (SEO) consultants. In the digital age, having a stunning website is not enough; you need to be found by your target audience.</p>
		</CardFront>
		<CardBack>
			<h4 className="font-weight-bold text-color-light">&nbsp;</h4>
			<p className="font-weight-light text-color-light opacity-5">Our SEO experts are dedicated to unlocking the full potential of your online presence. With a deep understanding of search engine algorithms and user behavior, we employ cutting-edge strategies to optimize your website, improve keyword rankings, and drive organic traffic. From comprehensive keyword research to on-page optimization and link building, our consultants will propel your website to the top of search engine results, ensuring maximum visibility and increased conversions. Trust us to unravel the complexities of SEO and position your business for online success.</p>
			<a href="contact-us" className="btn btn-light btn-modern text-color-dark font-weight-bold">LEARN MORE</a>
		</CardBack>
	</Card>
    );
};

export default SearchEngineOptimization;