import { Card, CardFront, CardBack } from '@indigenux/design/components';
import React from 'react';
const CustomizedSolutions = (...rest) => {

	return (
  	<Card canFlip={true}>
		<CardFront>
			<strong className="font-weight-extra-bold text-color-dark line-height-1 text-13 mb-3 d-inline-block">
				<i className="icon-featured fas fa-handshake-angle text-color-light"></i>
			</strong>
			<h4 className="font-weight-bold text-color-primary text-4">Customized Solutions</h4>
			<p>Unleash your business potential with Customized Solutions from our expert consultants. Off-the-shelf solutions may not always meet the unique needs of your business. That's where we excel, offering unparalleled expertise in crafting customized solutions tailored to your specific requirements.</p>
		</CardFront>
		<CardBack>
			<h4 className="font-weight-bold text-color-light">&nbsp;</h4>
			<p className="font-weight-light text-color-light opacity-5">Our team of experienced consultants will collaborate closely with you to understand your pain points, goals, and constraints. Armed with this knowledge, we design and develop bespoke solutions that address your business challenges, streamline processes, and drive growth. From software development and system integration to workflow optimization and data analytics, our experts deliver cutting-edge, scalable solutions that align perfectly with your vision. Trust us to unlock the full potential of customized solutions and empower your business for long-term success.</p>
			<a href="contact-us" className="btn btn-light btn-modern text-color-dark font-weight-bold">LEARN MORE</a>
		</CardBack>
	</Card>
    );
};

export default CustomizedSolutions;