import React from 'react';

import {Footer, Topbar} from '@indigenux/design/components';
import { Main as IndiMain } from '@indigenux/design/layouts';

export default function Main({children }) {

  return (
    <>
      <Topbar isSticky={true}></Topbar>
      <IndiMain>{children}</IndiMain>
      <Footer></Footer>
    </>
  );
}
