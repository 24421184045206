import React from 'react';
import { Divider, IndiText, PageHeader, Heading } from '@indigenux/design/components';
import { Main } from '../../components';

import PageLogo from 'img/slides/johannes-plenio-fmTde1Fe23A-unsplash.jpg';

const InnovationView = () => {

	return (<Main>
		<PageHeader Category="Innovate">
			Innovation @<IndiText uxCSSClass="text-color-secondary"></IndiText>
		</PageHeader>
		<div className="container py-2">
			<div className="row">
				<div className="col">
					<img className="float-start img-fluid me-2" width="300" src={PageLogo} alt="Device" />

					<Heading level="h2">
						Unleash the power of technical innovation with <IndiText uxCSSClass="text-color-secondary"></IndiText>
					</Heading>

					<p className="lead">Welcome to the era of possibilities.</p>

					<p>In today's fast-paced world, where technology is at the forefront of every industry, staying ahead of the curve is paramount. That's where <IndiText uxCSSClass="text-color-secondary"></IndiText> comes in – a trailblazer in technical innovation, driving transformative change across businesses and industries.</p>

					<Divider uxCSSClass='divider-secondary'></Divider>
					<Heading level="h2">Why embrace technical innovation?</Heading>

					<ul className="list list-icons list-icons-style-2">
						<li><i className="fas fa-fan text-color-secondary"></i> Propel Your Business Forward: Embracing technical innovation is the key to propelling your business to new heights. By harnessing the latest advancements in technology, you can streamline operations, enhance productivity, and gain a competitive edge. <IndiText uxCSSClass="text-color-secondary"></IndiText> empowers you to seize these opportunities, making your business future-ready and primed for success.</li>
						<li><i className="fas fa-infinity text-color-secondary"></i> Solve Complex Challenges: Every business faces unique challenges, but with technical innovation, there are solutions waiting to be discovered. <IndiText uxCSSClass="text-color-secondary"></IndiText> specializes in analyzing your pain points and developing custom solutions that address your specific needs. Whether it's automating processes, optimizing workflows, or implementing cutting-edge software, we unlock the potential of technology to overcome your toughest hurdles.</li>
						<li><i className="fas fa-code-fork text-color-secondary"></i> Stay Agile and Adaptive: The business landscape is evolving at an unprecedented rate. To stay relevant, you must be agile and adaptive. Technical innovation allows you to embrace change, respond to market demands swiftly, and seize new opportunities. With <IndiText uxCSSClass="text-color-secondary"></IndiText> by your side, you'll have access to the latest tools, technologies, and strategies that empower you to adapt and thrive in a dynamic environment.</li>
						<li><i className="fas fa-people-group text-color-secondary"></i> Enhanced Customer Experiences: Technical innovation has revolutionized the way businesses engage with their customers. From personalized digital experiences to immersive virtual interactions, technology opens doors to unparalleled customer engagement and satisfaction. <IndiText uxCSSClass="text-color-secondary"></IndiText> helps you harness this potential, creating captivating experiences that build lasting relationships and drive customer loyalty.</li>
						<li><i className="fas fa-robot text-color-secondary"></i> Future-Proof Your Business: The only constant in today's world is change. By embracing technical innovation, you future-proof your business against disruptions and evolving market trends. <IndiText uxCSSClass="text-color-secondary"></IndiText> guides you in adopting forward-thinking strategies, leveraging emerging technologies such as artificial intelligence, blockchain, and IoT, to ensure your business remains at the forefront of innovation.</li>
					</ul>

					<Divider uxCSSClass='divider-secondary'></Divider>
					<Heading level="h2">
						Choose <IndiText uxCSSClass="text-color-secondary"></IndiText> for technical innovation
					</Heading>

					<p>At <IndiText uxCSSClass="text-color-secondary"></IndiText>, we believe that technical innovation is the catalyst for growth and success. Our team of visionary experts is dedicated to pushing the boundaries of what's possible, helping businesses like yours thrive in a rapidly evolving digital landscape. Partner with us to unlock the full potential of technical innovation and lead the way in your industry.</p>

					<p>Experience the power of technical innovation. Choose <IndiText uxCSSClass="text-color-secondary"></IndiText>.</p>
				</div>
			</div>

		</div>
	</Main>
	);
};

export default InnovationView;