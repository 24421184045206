import { Card, CardFront, CardBack } from '@indigenux/design/components';
import React from 'react';
const BrandIdentity = (...rest) => {

	return (
  	<Card canFlip={true}>
		<CardFront>
			<strong className="font-weight-extra-bold text-color-dark line-height-1 text-13 mb-3 d-inline-block">
				<i className="icon-featured fas fa-compass-drafting text-color-light"></i>
			</strong>
			<h4 className="font-weight-bold text-color-primary text-4">Brand & Identity</h4>
			<p>Elevate Your Brand to New Heights with Our Expert Brand & Identity Consultants. Your brand is the beating heart of your business, and crafting a powerful identity is essential for standing out in a crowded marketplace. </p>
		</CardFront>
		<CardBack>
			<h4 className="font-weight-bold text-color-light">&nbsp;</h4>
			<p className="font-weight-light text-color-light opacity-5">We specialize in brand and identity, offering unrivaled expertise to help you define and differentiate your brand. With a deep understanding of consumer psychology and market trends, our consultants will work closely with you to develop a compelling brand strategy, design captivating visuals, and establish a cohesive brand identity that resonates with your target audience. Trust us to breathe life into your brand and create a lasting impression that sets you apart from the competition.</p>
			<a href="contact-us" className="btn btn-light btn-modern text-color-dark font-weight-bold">LEARN MORE</a>
		</CardBack>
	</Card>
    );
};

export default BrandIdentity;