import { Card, CardFront, CardBack } from '@indigenux/design/components';
import React from 'react';
const SystemArchitecture = (...rest) => {

	return (
  	<Card canFlip={true}>
		<CardFront>
			<strong className="font-weight-extra-bold text-color-dark line-height-1 text-13 mb-3 d-inline-block">
				<i className="icon-featured fas fa-pen text-color-light"></i>
			</strong>
			<h4 className="font-weight-bold text-color-primary text-4">Systems Architecture</h4>
			<p>When it comes to software architecture, precision and foresight are the keys to
				success. Unlock the full potential of your applications with a team of seasoned
				experts specializing in software architecture. </p>
		</CardFront>
		<CardBack>
			<h4 className="font-weight-bold text-color-light"></h4>
			<p className="font-weight-light text-color-light opacity-5">We offer unparalleled
				expertise to guide you through every phase of your project. From designing
				robust, scalable architectures to optimizing performance and ensuring seamless
				integration, our consultants bring a wealth of knowledge and experience to the
				table. Let us help you build a rock-solid foundation for your software solutions
				and elevate your technical capabilities to new heights.</p>
			<a href="contact-us" className="btn btn-light btn-modern text-color-dark font-weight-bold">LEARN MORE</a>
		</CardBack>
	</Card>
    );
};

export default SystemArchitecture;