import {React, useState } from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { Page, Throbber } from '@indigenux/design';

import commonRoutes from 'views/routes';

const App = () => {
  /* TODO: Load languages, resources, messages, and other common elements here and cache them here 
    -- Make sure to pass these elements down through routes
  */

  //const appFactory = 

  return (
    <Page>
      <BrowserRouter>
        <Routes>
          {
            commonRoutes.map((route, j) => {
              return (<Route key={j} path={route.path} element={route.element()} />)
            })}
          <Route path="*" element={<Navigate replace to="/not-found" />} />
        </Routes>
      </BrowserRouter>
    </Page>
  );
};

export default App;
