import React from 'react';
import { Main } from '../../components';
import { PageHeader, Heading, Input, TextArea } from '@indigenux/design/components';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

const ContactUsView = () => {

	const initialValues = {
		name: "",
		email: "",
		subject: "",
		message: ""
	};
	const schema = object().shape({
		name: string().required("Please enter your name.").max(100),
		email: string().required("Please enter your email.").email("Must be a valid email").max(100),
		subject: string().required("Please enter the subject.").max(100),
		message: string().required("please enter your message.").max(5000)
	});
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = (values) => {
		//Send values to API
		console.log("Sending the following information: ", values);
		enqueueSnackbar("Success!  Your message has been sent to us.");
	};

	return (<Main>
		<PageHeader Category="Contact">
			Contact Us
		</PageHeader>
		<div className="container">

			<div className="row py-4">
				<div className="col-lg-6">

					<Heading level="h2">Contact Us</Heading>
					<p className="mb-4">Feel free to ask for details, don't save any questions!</p>

					<Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} validate={(values) => { }}>
						{(formik) => {

							return (
								<Form>
									<div className="row">
										<div className="form-group col-lg-6">
											<Input text="Full Name" value={formik.values.name} onChange={formik.handleChange} required maxLength="100" name="name"></Input>
										</div>
										<div className="form-group col-lg-6">
											<Input text="Email Address" value={formik.values.email} onChange={formik.handleChange} required maxLength="100" name="email"></Input>
										</div>
									</div>
									<div className="row">
										<div className="form-group col">
											<Input text="Subject" value={formik.values.subject} onChange={formik.handleChange} required maxLength="100" name="subject"></Input>
										</div>
									</div>
									<div className="row">
										<div className="form-group col">
											<TextArea maxLength="5000" value={formik.values.message} onChange={formik.handleChange} text="Additional Information" name="message" required></TextArea>
										</div>
									</div>
									<div className="row">
										<div className="form-group col">
											<input type="submit" value="Send Message" className="btn btn-primary btn-modern" data-loading-text="Loading..." />
										</div>
									</div>
								</Form>
							);
						}
						}
					</Formik>

				</div>
				<div className="col-lg-6">

					<div className="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="800">
						<h4 className="mt-2 mb-1">Our <strong>Office</strong></h4>
						<ul className="list list-icons list-icons-style-2 mt-2">
							<li><i className="fas fa-phone top-6"></i> <strong className="text-dark">Phone:</strong> (513) 253-8868</li>
							<li><i className="fas fa-envelope top-6"></i> <strong className="text-dark">Email:</strong> <a href="mailto:you@indigenux.com">you@indigenux.com</a></li>
						</ul>
					</div>

					<div className="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="950">
						<h4 className="pt-5">Business <strong>Hours</strong></h4>
						<ul className="list list-icons list-dark mt-2">
							<li><i className="far fa-clock top-6"></i> Monday - Friday - 9am to 5pm</li>
							<li><i className="far fa-clock top-6"></i> Saturday - 9am to 2pm</li>
							<li><i className="far fa-clock top-6"></i> Sunday - Closed</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</Main>
	);
};

export default ContactUsView;