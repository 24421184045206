import React from 'react';
import { Divider, IndiText, PageHeader, Heading } from '@indigenux/design/components';
import { Main } from '../../components';

import PageLogo from 'img/slides/The-Power-of-Believing-in-Your-Team-480x320.jpg.webp';

const SuccessView = () => {

	return (<Main>
		<PageHeader Category="Success">
			Innovation @<IndiText uxCSSClass="text-color-light"></IndiText>
		</PageHeader>

		<div className="container py-2">
			<div className="row">
				<div className="col">

					<img className="float-start img-fluid me-2" width="300" src={PageLogo} alt="power of believing" />

					<Heading level="h2">
						Introducing <IndiText uxCSSClass="text-color-tertiary"></IndiText>: Your trusted software consultancy partner
					</Heading>

					<p className="lead">Unlock the potential of technology with <IndiText uxCSSClass="text-color-tertiary"></IndiText>.</p>

					<p>Are you looking to enhance your business operations, streamline processes, and maximize efficiency? Look no further than <IndiText uxCSSClass="text-color-tertiary"></IndiText>, your premier software consultancy partner. With our unrivaled expertise and tailored solutions, we empower businesses like yours to embrace the digital age and thrive in a competitive landscape.</p>

					<Divider uxCSSClass='divider-tertiary'></Divider>
					<h2>Why choose <IndiText uxCSSClass="text-color-tertiary"></IndiText>?</h2>

					<ul className="list list-icons list-icons-style-2">
						<li><i className="fas fa-code text-color-tertiary"></i> Unparalleled Expertise: Our team of seasoned software consultants brings a wealth of experience and technical know-how to the table. We have successfully delivered cutting-edge solutions to clients across diverse industries, ranging from startups to enterprise-level corporations. With our deep understanding of the latest technologies and industry best practices, we are primed to solve even the most complex software challenges.</li>
						<li><i className="fas fa-sliders text-color-tertiary"></i> Customized Solutions: At <IndiText uxCSSClass="text-color-tertiary"></IndiText>, we believe that one size does not fit all. We take the time to understand your unique business requirements, goals, and constraints. Armed with this knowledge, we develop customized software solutions that address your specific needs and drive tangible results. From software development and integration to system optimization and project management, we've got you covered at every step of your digital transformation journey.</li>
						<li><i className="fas fa-arrows-spin text-color-tertiary"></i> Agile Methodology: In today's fast-paced business environment, agility, dependability, and being able to scale without sacrificing quality are all keys to staying ahead of the curve. Our agile development approach ensures quick turnaround times, continuous feedback loops, and flexibility in adapting to evolving project requirements. By embracing iterative development cycles, we deliver software solutions that align seamlessly with your evolving business goals, keeping you ahead of the competition.</li>
						<li><i className="fas fa-bug text-color-tertiary"></i> Quality Assurance: We are committed to delivering excellence in every project we undertake. Our rigorous quality assurance processes ensure that your software is thoroughly tested, secure, and meets the highest industry standards. We leave no stone unturned when it comes to ensuring the reliability, stability, and performance of your software solution.</li>
						<li><i className="fas fa-handshake text-color-tertiary"></i> End-to-End Support: Our partnership with you extends beyond the initial implementation. We provide comprehensive support and maintenance services to ensure that your software remains robust and up-to-date. Our dedicated support team is always at hand to address any concerns, provide timely assistance, and help you leverage your software investment to its fullest potential.</li>
					</ul>

					<Divider uxCSSClass='divider-tertiary'></Divider>
					<Heading level="h2">
						Experience the <IndiText uxCSSClass="text-color-tertiary"></IndiText> advantage today
					</Heading>

					<p>Don't let outdated systems and inefficient processes hold your business back. Embrace the power of technology and propel your organization forward with <IndiText uxCSSClass="text-color-tertiary"></IndiText> as your trusted software consultancy partner.</p>

					<p className="lead"><a href="contact-us">Contact us</a> today to schedule a consultation and discover how our tailored solutions can revolutionize your business.</p>
					<p>Unlock your business potential. Choose <IndiText uxCSSClass="text-color-tertiary"></IndiText>.</p>
				</div>
			</div>

		</div>
	</Main>
	);
};

export default SuccessView;