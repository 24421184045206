import React from 'react';

import {
  HomeView, 
  ServicesView, 
  CreativeView,
  InnovationView,
  SuccessView,
  ContactUsView, 
  DemoView,
  LoginView

} from 'views';

import {
  NotFound, 
  ServerError,
  PrivacyPolicy, 
  DataRequest, 
  CookiesPolicy,
  TermsOfUse, 
} from '@indigenux/design/views';

const routes = [
  {
    path: '/',
    element: (isLoading, setIsLoading) => <HomeView isLoading={isLoading} setIsLoading={setIsLoading} />,
  },
  {
    path: '/index',
    element: () => <HomeView />,
  },
  {
    path: '/services',
    element: () => <ServicesView />,
  },
  {
    path: '/creative',
    element: () => <CreativeView />,
  },
  {
    path: '/innovate',
    element: () => <InnovationView />,
  },
  {
    path: '/success',
    element: () => <SuccessView />,
  },
  {
    path: '/contact-us',
    element: () => <ContactUsView />,
  },
  {
    path: '/demo',
    element: () => <DemoView />,
  },
  //Security/Auth routes
  {
    path: '/login',
    element: () => <LoginView />,
  },
  {
    path: '/logout',
    element: () => <LoginView />,
  },
  {
    path: '/otp',
    element: () => <LoginView />,
  },
  {
    path: '/not-found',
    element: () => <NotFound />,
  },
  {
    path: '/error',
    element: () => <ServerError />,
  },
  {
    path: '/privacy',
    element: () => <PrivacyPolicy />,
  },
  {
    path: '/data',
    element: () => <DataRequest />,
  },
  {
    path: '/cookies',
    element: () => <CookiesPolicy />,
  },
  {
    path: '/terms',
    element: () => <TermsOfUse />,
  }
];

export default routes;
