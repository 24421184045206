import React from 'react';
import { Carousel, CarouselItem, IndiText, Heading, Link, CompanyLogo} from '@indigenux/design/components';
/* Images */
import slide1 from "img/slides/slide-1.jpg";
import slide2 from "img/slides/slide-2.jpg";
import slide3 from "img/slides/christmas-4635228_1920.jpg";
import slide4 from "img/slides/slide-5.jpg";
import slide5 from "img/slides/computer-564136_1920.jpg";
import slide6 from "img/slides/lighthouse-6369963_1920.jpg";
import slide7 from "img/slides/purse-1478852_1920.jpg";
import slide8 from "img/slides/paper-3406864_1920.jpg";
import slide9 from "img/slides/conference-table-593355_1920.jpg";
import slideTopBorder from "img/slides/stock/slide-title-border.png";

const Highlights = () => {
  
  return (
    <Carousel id="carousel-1" autoPlay={true} infiniteLoop={true} interval={5000}>
        <CarouselItem id={'carousel-item1'} backgroundImage={slide1}>
            <CompanyLogo animated={true} animation={"fadeIn"} style={{width: "100px", animationDelay: "1500ms"}}/> 
            <Heading level="h2" cssName="text-color-light font-weight-light text-13 display-1 mb-4 fadeIn animated" style={{animationDelay: "1500ms"}}>
                <IndiText uxCSSClass="text-color-primary"></IndiText>
            </Heading>
            <p className="text-4-5 text-color-light font-weight-light opacity-7 text-center mb-4" mdata-plugin-animated-letters="true" data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 25}">
                We are creative technology consultants.
            </p>
            <div className="fadeInDownShorterPlus animated" style={{animationDelay: '3300ms'}}>
                <div className="d-flex align-items-center mt-2">
                    <Link href="success" className="btn btn-light btn-modern text-color-primary font-weight-bold text-2 py-3 btn-px-4">LEARN MORE</Link>
                    <Link href="contact-us" className="btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 ms-4">GET STARTED NOW <i className="fas fa-arrow-right ms-2"></i></Link>
                </div>
            </div>
        </CarouselItem>
        <CarouselItem id={'carousel-item2'} backgroundImage={slide2} className="overlay-color-primary fadeIn animated overlay overlay-show overlay-op-8">
            <Heading level="h3" cssName="position-relative text-color-light text-4 line-height-5 font-weight-medium px-4 mb-2 fadeInDownShorterPlus animated">
                <span className="position-absolute right-100pct top-50pct transform3dy-n50 opacity-3">
                    <img src={slideTopBorder} className="w-auto fadeInRightShorter animated" style={{animationDelay: '250ms'}} alt="" />
                </span>
                UNIFIED SOFTWARE SOLUTIONS, We are <IndiText uxCSSClass="text-color-primary"></IndiText>.
                <span className="position-absolute left-100pct top-50pct transform3dy-n50 opacity-3">
                    <img src={slideTopBorder} className="w-auto fadeInLeftShorter animated" style={{animationDelay: '250ms'}} alt="" />
                </span>
            </Heading>
            <Heading level="h2" cssName="porto-big-title text-color-light font-weight-extra-bold mb-3 fadeInRightShorterOpacity animated" mdata-plugin-animated-letters="true" data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 250, 'animationName': 'fadeInRightShorterOpacity', 'letterclassName': 'd-inline-block'}">
                <IndiText uxCSSClass="text-color-light font-weight-extra-bold"></IndiText>
            </Heading>
            <p className="text-4 text-color-light font-weight-light text-center mb-0" mdata-plugin-animated-letters="true" data-plugin-options="{'startDelay': 2000, 'minWindowWidth': 0}">
                Need a new website or redesign?</p>
        </CarouselItem>
        <CarouselItem id={'carousel-item3'} backgroundImage={slide9}>
            <Heading level="h2" cssName="text-color-light font-weight-extra-bold text-13 mb-4" 
                data-appear-animation="blurIn" data-plugin-options="{'minWindowWidth': 0}">SYSTEMS INTEGRATION</Heading>
            <p className="text-4-5 text-color-light font-weight-light opacity-7 text-center mb-4" 
                mdata-plugin-animated-letters="true" data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 25}">
                We have the experience to ensure a successful outcome.</p>
            <div className="fadeInUpShorter animated" style={{animationDelay: "3300"}}>
                <div className="d-flex align-items-center mt-2">
                    <Link href="success" className="btn btn-light btn-modern text-color-primary font-weight-bold text-2 py-3 btn-px-4">LEARN MORE</Link>
                    <Link href="contact-us" className="btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 ms-4">READY TO GET STARTED? <i className="fas fa-arrow-right ms-2"></i></Link>
                </div>
            </div>
        </CarouselItem>
        <CarouselItem id={'carousel-item4'} backgroundImage={slide3}>
            <Heading level="h2" cssName="text-color-light font-weight-extra-bold text-13 mb-4 blurIn animated" style={{animationDelay: "1000ms"}}>YOU ARE MORE THAN JUST A CLIENT</Heading>
            <p className="text-4-5 text-color-light font-weight-light opacity-7 text-center mb-4" 
                mdata-plugin-animated-letters="true" data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 25}">
                And your needs require more than a cookie cutter solution.</p>
            <div className="fadeInUpShorter animated" style={{animationDelay: "3300ms"}}>
                <div className="d-flex align-items-center mt-2">
                    <Link href="success" className="btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 ms-4">LEARN ABOUT OUR TAILOR MADE SOLUTIONS <i className="fas fa-arrow-right ms-2"></i></Link>
                </div>
            </div>
        </CarouselItem>
        <CarouselItem id={'carousel-item5'} backgroundImage={slide4}>
            <Heading level="h2" cssName="text-color-light font-weight-extra-bold text-13 mb-4 blurIn animated" style={{animationDelay: "1000ms"}}>WE ARE MORE THAN CONSULTANTS</Heading>
                <p className="text-4-5 text-color-light font-weight-light opacity-7 text-center mb-4" 
                mdata-plugin-animated-letters="true" data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 25}">
                    We believe in a unified approach to technology consulting. </p>
                <div className="fadeInUpShorter animated" style={{animationDelay: "3300ms"}}>
                    <div className="d-flex align-items-center mt-2">
                        <Link href="success.html" className="btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 ms-4">HOW WE DO BUSINESS <i className="fas fa-arrow-right ms-2"></i></Link>
                    </div>
                </div>
        </CarouselItem>
        <CarouselItem id={'carousel-item6'} backgroundImage={slide5}>
            <Heading level="h2" cssName="text-color-light font-weight-extra-bold text-13 mb-4 blurIn animated" style={{animationDelay: "1000ms"}}>TECHNICAL INNOVATION</Heading>
            <p className="text-4-5 text-color-light font-weight-light opacity-7 text-center mb-4" mdata-plugin-animated-letters="true" data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 25}">
                From data to frontend, we do it all.
            </p>
            <div className="fadeInUpShorter animated" style={{animationDelay: "3300ms"}}>
                <div className="d-flex align-items-center mt-2">
                    <Link href="innovate.html" className="btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 ms-4">HOW WE DO INNOVATION <i className="fas fa-arrow-right ms-2"></i></Link>
                </div>
            </div>
        </CarouselItem>
        <CarouselItem id={'carousel-item7'} backgroundImage={slide6}>
            <Heading level="h2" cssName="text-color-light font-weight-extra-bold text-13 mb-4 blurIn animated" style={{animatedDelay: "1000ms"}}>IMMERSIVE DESIGN</Heading>
            <p className="text-4-5 text-color-light font-weight-light opacity-7 text-center mb-4" 
                mdata-plugin-animated-letters="true" data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 25}">
                Our in-house creative team has your design needs covered.</p>
            <div className="fadeInUpShorter animated" style={{animationDelay: "3300ms"}}>
                <div className="d-flex align-items-center mt-2">
                    <Link href="creative" className="btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 ms-4">HOW WE DO CREATIVE <i className="fas fa-arrow-right ms-2"></i></Link>
                </div>
            </div>
        </CarouselItem>
        <CarouselItem id={'carousel-item8'} backgroundImage={slide7}>
            <Heading level="h2" cssName="text-color-light font-weight-extra-bold text-13 mb-4" data-appear-animation="blurIn" data-plugin-options="{'minWindowWidth': 0}">CUSTOM SOLUTIONS</Heading>
            <p className="text-4-5 text-color-light font-weight-light opacity-7 text-center mb-4" 
                mdata-plugin-animated-letters="true" data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 25}">
                From concept to captivating, let us help you succeed!</p>
            <div className="fadeInUpShorter animated" style={{animationDelay: "3300ms"}}>
                <div className="d-flex align-items-center mt-2">
                    <Link href="contact-us" className="btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 ms-4">FIND OUT WHAT WE CAN DO FOR YOU <i className="fas fa-arrow-right ms-2"></i></Link>
                </div>
            </div>
        </CarouselItem>
        <CarouselItem id={'carousel-item9'} backgroundImage={slide8}>
            <Heading level="h2" cssName="text-color-light font-weight-extra-bold text-13 mb-4 blurIn animated" style={{animatedDelay: "1000ms"}}>
                VISION STRATEGY
            </Heading>
            <p className="text-4-5 text-color-light font-weight-light opacity-7 text-center mb-4" mdata-plugin-animated-letters="true" data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 25}">
                Future-proof your business against disruptions and evolving trends.
            </p>
            <div className="fadeInUpShorter animated" style={{animationDelay: "3300ms"}}>
                <div className="d-flex align-items-center mt-2">
                    <Link href="success" className="btn btn-light btn-modern text-color-primary font-weight-bold text-2 py-3 btn-px-4">LEARN MORE</Link>
                    <Link href="contact-us" className="btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 ms-4">GET STARTED NOW <i className="fas fa-arrow-right ms-2"></i></Link>
                </div>
            </div>
        </CarouselItem>
    </Carousel>
  );
};

export default Highlights;
