import { Card, CardFront, CardBack } from '@indigenux/design/components';
import React from 'react';
const SupportServices = (...rest) => {

	return (
  	<Card canFlip={true}>
		<CardFront>
			<strong className="font-weight-extra-bold text-color-dark line-height-1 text-13 mb-3 d-inline-block">
				<i className="icon-featured fas fa-phone-flip text-color-light"></i>
			</strong>
			<h4 className="font-weight-bold text-color-primary text-4">Support Services</h4>
			<p>Reliable support services to empower your bsiness. In the fast-paced world of business, having a reliable support system is essential. We offer expertise in comprehensive support services to ensure your operations run smoothly and efficiently.</p>
		</CardFront>
		<CardBack>
			<h4 className="font-weight-bold text-color-light">&nbsp;</h4>
			<p className="font-weight-light text-color-light opacity-5">With a team of dedicated professionals, we provide timely assistance, troubleshooting, and maintenance services to address any technical challenges you may encounter. Whether it's software updates, system upgrades, or user training, our experts are committed to resolving issues swiftly, minimizing downtime, and maximizing productivity. Trust us to be your reliable support partner, offering the peace of mind that comes with knowing your business is backed by our expert assistance every step of the way.</p>
			<a href="contact-us" className="btn btn-light btn-modern text-color-dark font-weight-bold">LEARN MORE</a>
		</CardBack>
	</Card>
    );
};

export default SupportServices;